#secondaryView {
  width: 400px;
  background-color: white;
  flex-shrink: 0;
  padding: 10px;
  border-left: 1px solid rgba(0, 0, 0, 0.125);
  overflow-y: auto;
  overflow-x: hidden; // Firefox ¯\_(ツ)_/¯

  .header {
    width: calc(100% + 20px);
    transform: translate(-10px, -10px);
    // TODO Temporal transition because hue-rotate delay
    opacity: 0;
    animation-name: fadeIn;
    animation-fill-mode: forwards;
    animation-timing-function: ease-in;
    animation-duration: 0.2s;
    animation-delay: 0.5s; // Workaround ! Delete all
  }
}
