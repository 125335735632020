.modalContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgb(0 0 0 / 20%);
  z-index: 1000;

  opacity: 0;
  animation-name: fadeIn;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in;
  animation-duration: 0.25s;

  .card {
    min-width: 300px;
    box-shadow: 0 8px 20px 0 rgb(0 0 0 / 30%);
    max-width: calc(100vw - 130px);
    max-height: calc(100vh - 130px);

    .card-header {
      display: grid;
      grid-template-columns: 1fr auto;
      padding: 1rem !important;
      align-items: center;
      font-weight: bold;

      .close button {
        border-radius: 50%;
        width: 30px;
        height: 30px;
        text-align: center;
        padding: 0;

        svg {
          margin: 0;
        }
      }
    }
  }

  .card-body {
    overflow-y: auto;
    padding: 0;
  }
}
