@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeIn2 { // Equals to fadeIn. It's used to force to repeat the animation
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.auth,
#panel,
#secondaryView,
#secondaryView > div,
#primaryView .entityCardContainer > .card,
#primaryView .entityTableContainer tr {
  opacity: 0;
  animation-name: fadeIn;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in;
  animation-duration: 0.5s;
}

// Chrome default width
::-webkit-scrollbar {
  width: 15px;
}
// Chrome scrollbar colors
::-webkit-scrollbar-thumb {
  background: #c1c1c1;
  &:hover {
    background: #a8a8a8;
  }
}

.btn svg {
  margin-right: 0.5rem;
  transform: translateY(1px);
}
.btn svg:only-child {
  margin-right: 0;
}
// Chrome datalist arrow remove
input[list]::-webkit-calendar-picker-indicator {
  opacity: 0;
}
