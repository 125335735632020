#primaryView {
  flex-grow: 1;
  overflow-x: auto;

  .primaryViewContainer {
    position: relative;
    display: flex;
    flex-direction: column;
    height: calc(100vh - 50px);
    overflow: hidden;

    .entitiesContainer {
      padding: 1rem;
      overflow: auto;
      flex-grow: 1;

      .cardsContainer,
      .tableContainer {
      }
    }
  }


  .cardsContainer {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    grid-gap: 1rem;

    & > .card {
      transition: box-shadow 0.25s, border-bottom 0.25s;

      &.cardSelectedToDelete {
        box-shadow: 0 0 0 5px var(--bs-danger);
        background-color: rgb(211 47 47 / 5%);
        border: 1px solid var(--bs-danger);

        td, .cardTitle, .conditionalLink:not(:hover) {
          color: var(--bs-danger) !important;
        }

        .card-footer {
          background-color: transparent;
        }
      }

      .card-header {
        padding: 0;
        overflow: hidden;

        img {
          width: 100%;
          transition: border-radius 0.25s;
        }
      }

      .card-body {
        padding: 0.5rem 0.5rem;

        .cardTitle {
          font-size: 16px;
          text-align: center;
          margin-bottom: 0.5rem
        }

        .entityDataTable {
          margin: 0 auto;
          font-size: 11px;
          width: 100%;

          td {
            padding: 0 6px;
            white-space: nowrap;

            &:first-child {
              text-align: right;
              color: gray;
              width: 1px; // Min content

              &:after {
                content: ':';
              }
            }

            &:last-child {
              text-align: left;
              overflow: hidden;
              text-overflow: ellipsis;
              max-width: 1px; // Prevent large text expand table width
            }
          }
        }
      }

      .card-footer {
        background-color: white;
        padding: 0.5rem;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;

        button.btn {
          &:not(.btn-primary) {
            min-width: 34px;
          }
        }
      }
    }
  }

  .tableContainer {
    table {
      outline: solid #dee2e6 1px;
    }

    thead {
      position: sticky;
      top: 0;
      z-index: 1;

      &:after {
        content: "";
        display: block;
        background-color: #f8f8f8;
        position: absolute;
        left: -1rem;
        right: -1rem;
        top: -1rem;
        bottom: 0;
        z-index: 2;
      }

      tr {
        background-color: white;
        position: relative;
        z-index: 3;
        outline: solid #dee2e6 1px;
      }
    }

    tbody {
      background-color: white;
      position: relative;
      z-index: 0;
    }

    td, th {
      vertical-align: middle;
      white-space: nowrap;
    }

    th {
      white-space: nowrap;
    }

    td:last-child {
      text-align: right;
      padding: 0 0.5rem 0 0;
    }

    tr {
      transition: box-shadow 0.25s;

      &.rowSelectedToDelete {
        box-shadow: 5px 0 0 0 var(--bs-danger), -5px 0 0 0 var(--bs-danger);
        background-color: rgb(211 47 47 / 5%);

        td:not(:last-child) {
          color: var(--bs-danger);
        }

        td .conditionalLink:not(:hover) {
          color: var(--bs-danger) !important;
        }
      }
      &:last-child td {
        border-bottom-width: 0;
      }
    }
  }
}
