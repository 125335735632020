.confirmDeleteBox {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--bs-danger);
  font-size: 1rem;
  overflow: hidden;
  z-index: 10;

  min-height: 0;
  max-height: 0;
  padding: 0;
  border: none;
  transition: all 0.2s;

  &.visible {
    min-height: 40px;
    max-height: 40px;
    padding: 0.2rem 1rem;
    border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  }

  .confirmDeleteBoxText {
    transform: translateY(1px);
    display: inline-block;
    color: white;
  }
}
