$listItemHeight: 32px;

@keyframes slideDown {
  from {
    height: 0;
  }
  to {
    height: $listItemHeight;
  }
}

.guestList {
  width: 100%;
  border-left: solid #ced4da 1px;
  margin-top: 0.5rem;

  & > div {
    display: flex;
    width: 100%;
    padding: 1.5px 5px 1.5px 13px;
    animation-name: slideDown;
    animation-fill-mode: forwards;
    animation-duration: 0.2s;

    &:hover {
      background-color: var(--bs-light);
    }

    & > div:first-child {
      flex: 1 auto;
      align-self: center;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      padding-right: 10px;
    }

    & > div:last-child {
      flex: 0 auto;
      padding: 0;

      button {
        border-radius: 50%;
        transform: scale(0.7) translateY(-1px);
      }
    }
  }
}

$gridWidth: 30px;
.deviceList {
  width: 100%;
  border-left: solid #ced4da 1px;

  & > div {
    padding: 1.5px 0 1.5px 13px;

    &:hover {
      background-color: var(--bs-light);

    }

    .devicesWithKeyGrid {
      display: grid;
      grid-template-columns: 1fr $gridWidth $gridWidth $gridWidth $gridWidth $gridWidth $gridWidth $gridWidth;
      height: $listItemHeight;
      align-items: center;

      div:first-child {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        padding-right: 10px;
      }

      button:nth-child(2) {
        border-radius: 0.25rem 0 0 0.25rem;
      }

      button:nth-child(3),
      button:nth-child(4),
      button:nth-child(5),
      button:nth-child(6) {
        border-radius: 0;
      }

      button:nth-child(7) {
        border-radius: 0 0.25rem 0.25rem 0;
      }

      .btn {
        padding: 0;
        text-align: center !important;
        z-index: 1;
      }

      .btn-primary {
        z-index: 2;
      }

      .btn:focus {
        z-index: 3;
      }
    }
  }

  .miniForm {
    padding: 1.5px 0;
    $labelWidth: 110px;

    label {
      font-size: 0.8rem;
      width: $labelWidth;

      &:after {
        content: ':';
      }
    }

    input {
      width: calc(100% - #{$labelWidth});
      display: inline-block;
    }
  }
}

.assignedKeys {
  width: 100%;
  border-left: solid #ced4da 1px;

  & > div {
    display: flex;
    width: 100%;
    padding: 1.5px 5px 1.5px 13px;

    &.stayKey {
      font-size: 0.8rem;
    }

    &:hover {
      background-color: var(--bs-light);
    }

    & > div:first-child {
      flex: 1 auto;
      align-self: center;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      padding-right: 10px;
    }

    & > div:last-child {
      flex: 0 auto;
      padding: 0;

      button {
        border-radius: 50%;
        transform: scale(0.7) translateY(-1px);
      }
    }
  }
}

.clickable {
  cursor: pointer;
}
