@keyframes halfRotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(180deg);
  }
}
.loadingSpinner {
  display: block;
  margin: 0 auto;
  padding: 1rem;
  width: 5rem;
  height: 5rem;
  overflow: visible;
  svg {
    animation-name: halfRotate;
    animation-iteration-count: infinite;
    animation-timing-function: cubic-bezier(0.49, -0.15, 0.57, 1.26);
    animation-duration: 0.8s;
    width: 100% !important;
    height: 100%;
    color: var(--bs-gray-dark);
  }
  // Inline not used. Maybe future for text loading, for example when ids become names. Create new prop for access this css
  &.loadingSpinner-inline {
    display: inline-block;
    width: 1rem;
    height: 1rem;
    overflow: visible;
  }
}


