.conditionalLink {
  cursor: pointer;
  display: block;
  color: var(--bs-primary);
  width: fit-content;
  max-width: 100%;
  text-align: left;
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;


  &:hover {
    box-shadow: -4px 0 0 0 var(--bs-primary),
    -3px 0 0 0 var(--bs-primary),
    -2px 0 0 0 var(--bs-primary),
    -1px 0 0 0 var(--bs-primary),
    0 0 0 0 var(--bs-primary),
    1px 0 0 0 var(--bs-primary),
    2px 0 0 0 var(--bs-primary),
    3px 0 0 0 var(--bs-primary),
    4px 0 0 0 var(--bs-primary);
    border-radius: 5px;
    background-color: var(--bs-primary);
    color: white;
  }

}
