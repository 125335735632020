// Override bootstrap colors:

$blue: #69849B;
$green: #699b99;
$cyan: #699b99;
$yellow: #d0c3bd;
$red: #c33124;

@import "./node_modules/bootstrap/scss/bootstrap.scss";

$theme-colors: (
        "primary":    $blue,
        //"secondary":  $secondary,
        "success":    $green,
        "info":       $cyan,
        "warning":    $yellow,
        "danger":     $red,
        //"light":      $light,
        //"dark":       $dark
);

.table > :not(:last-child) > :last-child > * {
  border-bottom-width: 0;
}

.btn-group > .btn:focus,
.btn-group-vertical > .btn:focus{
  z-index: 2;
}

.btn-light {
  color: var(--bs-gray-dark);
}

.btn-group-sm > .btn {
  color: white;
}
.btn-primary {
  color: white;
}