.auth.cardContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  padding: 20px 0;
  user-select: none;

  .card {
    width: 350px;

    .card-footer {
      display: flex;
      flex-direction: column;
      align-items: center;
      background-color: white;

      .tip {
        font-size: 0.9rem;
      }
    }
  }

  .card-body img {
    display: block;
    width: 80%;
    margin: 12px auto 22px auto;
  }
  .card-body .mb-3 {
    text-align: center;
  }

  #nomorepass {
    color: #68ad7c;
    font-size: 12px;

    img {
      display: block;
      width: 180px;
      margin: 4px auto 0 auto;
      cursor: pointer;
    }
  }

  #languageSelector {
    position: absolute;
    right: 1rem;
    width: 70px;
    cursor: pointer;
    .dropdown {
      position: absolute;
      top: 38px;
      left: -1px;
      padding: 0;
      background: white;
      border: 1px solid #ced4da;
      border-radius: 0.25rem;
      box-shadow: 0 3px 10px 0 rgb(0 0 0 / 15%);
      cursor: default;
      overflow: hidden;

      li {
        list-style: none;
        white-space: nowrap;
        cursor: pointer;
        padding: 5px 12px;
        &:hover {
          background-color: var(--bs-light);
        }
        svg {
          margin-right: 10px;
        }
      }
    }
    svg {
      width: 18px;
      transform: translateY(-1px);
      border-radius: 3px;
    }
  }
}
