.modalQrContainer {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  gap: 1rem;
  text-align: center;
  width: 472px;
  padding: 1rem;
  margin: 0 auto;

  .modalQrButtons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 1rem;
    width: 100%;

    & button:only-child {
      margin: 0 auto;
    }
  }
}
