.newEntity.card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background: white;
  min-height: 200px;
  transition: background 0.2s !important;

  svg {
    width: 30px;
    height: 50px;
    margin-top: 28px;
    color: var(--bs-gray-dark);
  }

  div {
    color: white;
  }

  &:hover {
    background: var(--bs-primary);

    div, svg {
      color: white;
    }

  }
}

.newEntity:not(.card) {
  text-align: right;
  width: 1%;
  padding-left: 0;

  button {
    width: 100%;

    div {
      display: inline-block;
    }
  }
}
