$navWidth: 180px;
#panel {
  #header {
    position: relative;
    z-index: 20;
    background-color: var(--bs-primary);
    color: white;
    height: 50px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.3);
    display: flex;
    flex-direction: row;
    align-items: center;

    & > * {
    }

    #headerTitle {
      padding: 0 20px;
      font-size: 21px;
      font-weight: bold;
      width: $navWidth;
      cursor: default;
    }

    #headerTools {
      padding-left: 10px;
      display: flex;
      flex-grow: 1;
      align-items: center;

      & > * {
        margin-right: 10px;
      }

      select {
        width: 8rem;
      }

      input[type="number"] {
        width: 4rem;
        text-align: center;
        /* Chrome, Safari, Edge, Opera */
        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }

        /* Firefox */
        &[type=number] {
          -moz-appearance: textfield;
        }
      }

      .input-group {
        width: unset;
        background-color: white; // Background can be .borderDance (below) adding background effects
        border-radius: 0.2rem;

        .form-control, .form-select, .input-group-text {
          background-color: transparent; // Because input-group (parent class) has white background
        }

        .form-control:focus, .form-select:focus {
          border-color: transparent; // Because input-group (parent class) has white background
          box-shadow: 0 0 0 0.25rem rgb(255 255 255 / 25%);
        }

        // https://stackoverflow.com/a/52963366
        &.borderDance {
          $width: 100px;
          background-image: linear-gradient(90deg, #ced4da 50%, transparent 50%), linear-gradient(90deg, #ced4da 50%, transparent 50%);
          background-repeat: repeat-x, repeat-x;
          background-size: $width 3px, $width 3px;
          background-position: left top, right bottom;
          animation: border-dance 2s infinite linear;
          @keyframes border-dance {
            0% {
              background-position: left top, right bottom;
            }
            100% {
              background-position: left $width top, right $width bottom;
            }
          }
        }
      }
      button {
        white-space: nowrap;
      }
    }

    #viewMode {
      padding-left: 18px;
      button {
        box-shadow: 0 0 0 1px #f8f9fa;
        transition: box-shadow 0.25s;
      }
      .btn-light {
        box-shadow: 0 0 0 1px #f8f9fa, 0 0 0 4px #ffffff42;
        z-index: 100;
      }
    }

    #globalButtons {
      padding-right: 1rem;

      & > button {
        margin-left: 10px;

        .doorOpen {
          display: none;
        }

        .doorClosed {
          display: inherit;
        }

        &:hover {
          .doorOpen {
            display: inherit;
          }

          .doorClosed {
            display: none;
          }
        }
      }
    }
  }

  #body {
    display: flex;
    flex-direction: row;
    height: calc(100vh - 50px);

    #nav {
      width: $navWidth;
      flex-shrink: 0;
      overflow: auto;
      background-color: white;
      color: var(--bs-gray-dark);
      border-right: 1px solid rgba(0, 0, 0, 0.125);

      & > div {
        margin: 5px 0;
        padding: 10px 25px 10px 20px;
        cursor: pointer;
        white-space: nowrap;
        background-color: white;
        transition: background-color 0.2s, color 0.75s, box-shadow 0.75s;

        &:hover {
          background-color: var(--bs-light);
        }

        &[data-selected="true"] {
          background-color: var(--bs-light);
          box-shadow: inset 300px 0 0 2px var(--bs-primary);
          color: white;
        }

        & > * {
          display: inline-block;
        }

        & > svg {
          margin: 0 20px 0 0;
        }
      }
    }
  }
}
