.entityImage {
  overflow: hidden;
  position: relative;
  //padding-bottom: 42.8571429%; // Result of 3/7 * 100, thats because default 7:3 aspect ratio
  padding-bottom: 56.25%; // Result of 9/16 * 100, thats because default 16/9 aspect ratio

  img {
    // transition: transform 0.5s;
    width: 100%;
    position: absolute;
    height: 100%;
    object-fit: cover;
  }

  &.squareImgContainer {
    padding-bottom: 100%; // Result of 1/1 * 100, thats because square 1:1 aspect ratio
  }

  &.hasUploadIcon {
    img {
      transform: scale(1);
    }

    &:hover img {
      transform: scale(1.05);
    }

    .uploadIcon {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      cursor: pointer;
      color: white;
      opacity: 0;

      svg {
        display: block;
        height: 50px !important;
        width: 50px !important;
        margin: 0 auto;
        filter: drop-shadow(0 0 10px #00000044);
        transition: height 0.2s, width 0.2s;
      }

      div {
        text-align: center;
        font-size: 1rem;
        margin-top: 10px;
        text-shadow: 0 0 10px #00000066;
        transition: font-size 0.2s;
      }
    }

    &:hover .uploadIcon {
      opacity: 1;
    }
  }

  .keyTypeIcon {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;

    svg {
      width: 100%;
      height: 100%;
      padding: 15%;
      color: var(--bs-gray-dark);
      //color: #bc7c19;
      //filter: drop-shadow(1px 0 0 white) drop-shadow(-1px 0 0 white) drop-shadow(0 1px 0 white) drop-shadow(0 -1px 0 white) drop-shadow(0 0 15px #bc7c19aa);
    }
  }
}
