.logContainer {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 200px);
  min-width: 900px;
  .logDateFilters {
    display: flex;
    justify-content: center;
    background-color: #fcfcfc;
    padding: 0.5rem;
    .btn-group {
      button.btn {
        &.btn-light {
          border: solid #dee2e6 1px;
        }
        &:last-child {
          border-radius: 0;
        }
      }
    }
    .input-group {
      width: unset;
      input.form-control {
        &:first-of-type {
          border-radius: 0;
        }
        width: 170px;
        border: solid var(--bs-primary) 1px;
        transition: width 0.5s, padding 0.5s;
      }
    }
    &.hiddenInputs {
      .input-group {
        overflow: hidden;
      }
      .btn-group button.btn:last-child {
        border-radius: 0 0.25rem 0.25rem 0;
      }
      .input-group input.form-control {
        width: 0;
        padding: 0;
        border-width: 0;
      }
    }
  }
  .logTableContainer {
    overflow: auto;
    table.logTable {
      margin: 0;

      th, td {
        white-space: nowrap;
        padding: 1rem 0.75rem;

        &:first-child {
          padding-left: 1.5rem;
        }

        &:last-child {
          padding-right: 1.5rem;
        }
      }

      thead {
        position: sticky;
        top: 0;
        background-color: #fcfcfc;
        z-index: 1; // Prevent transformed button over it
        box-shadow: inset 0 -1px 0 0 rgb(222 226 230);
      }

      tbody {
        tr {
          &:hover {
            background-color: var(--bs-light);
          }

          td {
            padding: 0.5rem 0.75rem;

            button {
              padding: 0 5px;
              transform: translateY(-3px);
              margin-bottom: -3px;
            }
          }
        }
      }
    }
  }
}
